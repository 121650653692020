import validateGpAddress from "../../validators/validateGpAddress";
import validatePatientDetails from "../../validators/validatePatientDetails";
import validatePharmacyAddress from "../../validators/validatePharmacyAddress";
import validatePatientAddress from "../../validators/validatePatientAddress";
import { validateClinicalDetails } from "../../validators/validateClinicalDetails";

function validateForm(values: any) {
  const patientErrors = validatePatientDetails(values, true);
  const addressErrors = validatePatientAddress(values);
  const pharmacyErrors = validatePharmacyAddress(values);
  const gpErrors = validateGpAddress(values);
  const clinicalDetailsErrors = validateClinicalDetails(values);

  const combinedErrors = {
    ...patientErrors,
    ...addressErrors,
    ...pharmacyErrors,
    ...gpErrors,
    ...clinicalDetailsErrors
  };

  return combinedErrors;
}

export default validateForm;
