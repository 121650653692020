import React from "react";
import { useRecordContext, Labeled } from "react-admin";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { styled } from "@mui/material";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface Props {
  label: string;
  tab?: string;
  textContent?: string;
}

export function PatientLinkField(props: Props) {
  const { label, tab, textContent } = props;
  const record = useRecordContext();
  const patientId = get(record, "patients[0].id");
  const clientId = get(record, "clients[0].id");
  const navTab = tab ?? "general";

  let linkText = textContent;
  if (!linkText){
    const firstName = get(record, "patients[0].attributes.firstName");
    const lastName = get(record, "patients[0].attributes.lastName");
    linkText = `${firstName} ${lastName}`
  }

  return (
    <Labeled label={label}>
      <StyledLink aria-label="patient-link" to={`/patientRecords/${patientId}:${clientId}/${navTab}`}>
        <Typography variant="body1">
          {linkText}
        </Typography>
      </StyledLink>
    </Labeled>
  );
}