import React, { useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import {
  HealthmailAddressContextProvider,
  useHealthmailAddressContext,
} from "../../../context/HealthmailAddressContext";
import { FindAddressInputs } from "./FindAddressInputs";
import { SelectAddressInputs } from "./SelectAddressInputs";
import { SelectedAddressFields } from "./SelectedAddressFields";
import { HealthmailPharmacy } from "../../../types/healthmail";
import { Pharmacy } from "../../../types/patients";
import { extractGuid } from "../../../utilities/extractGuidFromFhirId";

interface Props {
  source: string;
}

export function HealthmailAddressFormInputs(props: Props) {
  return (
    <HealthmailAddressContextProvider>
      <ContextControls source={props.source} />
    </HealthmailAddressContextProvider>
  );
}

function ContextControls(props: Props) {
  const { source: addressSource } = props;
  const { setValue } = useFormContext();
  const healthmailAddressContext = useHealthmailAddressContext();
  const setSelectedInputAddress = healthmailAddressContext.selectAddress;
  const selectInputAddress = healthmailAddressContext.currentAddress;
  const currentSetAddress: Pharmacy = useWatch({ name: addressSource });

  const setCurrentAddress = useCallback(
    (selectedAddress: HealthmailPharmacy) => {
      const {
        pharmacyId,
        name,
        phoneNumber,
        emailAddress,
        address: { line, city, postalCode, country },
      } = selectedAddress;

      const prefixStrippedId = extractGuid(pharmacyId);

      const mappedPharmacy: Pharmacy = {
        organisationId: prefixStrippedId,
        name,
        address: {
          addressLine1: line.length > 0 ? line[0] : "",
          addressLine2: line.length > 1 ? line[1] : "",
          city: city,
          postCode: postalCode,
          countryCode: country,
        },
        phoneNumber,
        email: emailAddress,
      };

      setValue(addressSource, mappedPharmacy, { shouldDirty: true });
    },
    [setValue, addressSource]
  );

  useEffect(() => {
    if (!selectInputAddress || !selectInputAddress.pharmacyId) {
      return;
    }

    setCurrentAddress(selectInputAddress);
  }, [setCurrentAddress, selectInputAddress]);

  const handleRemoveAddress = () => {
    setValue(addressSource, null, { shouldDirty: true });
    setSelectedInputAddress("");
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SelectedAddressFields
          selectedAddress={currentSetAddress}
          onRemoveAddressClick={handleRemoveAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FindAddressInputs />
        <SelectAddressInputs />
      </Grid>
    </>
  );
}
