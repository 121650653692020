import { SelectInput, TextInput } from "ra-ui-materialui";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGooglePlaceAddressContext } from "../../../context/GooglePlaceAddressContext";
import { countrySelectChoices } from "../../../data/countryCodes";
import { EmailInput } from "../EmailInput";
import { PlaceType } from "./GooglePlaceAddressForm";
import { splitAddress } from "../../../utilities/splitAddress";

interface Props {
  sourcePrefix: string;
  type: PlaceType;
}

export interface GooglePlacesAddress {
  id: string;
  name: string;
  phoneNumber: string;
  address: string;
  city: string;
  postCode: string;
  countryCode: string;
  vicinity: string;
}

const inputStyles = {
  marginBottom: 0,
};

export function GooglePlaceAddressInputs(props: Props) {
  const { sourcePrefix, type } = props;
  const { currentAddress } = useGooglePlaceAddressContext();
  const { setValue } = useFormContext();

  const {
    id = "",
    name = "",
    phoneNumber = "",
    address = "",
    city = "",
    postCode = "",
    countryCode = "",
    vicinity = "",
  } = currentAddress || {};

  const { addressLine1, addressLine2 } = splitAddress({
    id,
    name,
    phoneNumber,
    address,
    city,
    postCode,
    countryCode,
    vicinity,
  });

  useEffect(() => {
    if (!currentAddress.id) return;

    setValue(`${sourcePrefix}.name`, name);
    setValue(`${sourcePrefix}.address.addressLine1`, addressLine1);
    setValue(`${sourcePrefix}.address.addressLine2`, addressLine2);
    setValue(`${sourcePrefix}.address.city`, city);
    setValue(`${sourcePrefix}.address.countryCode`, countryCode);
    setValue(`${sourcePrefix}.address.postCode`, postCode);
    setValue(`${sourcePrefix}.phoneNumber`, phoneNumber);
  }, [
    currentAddress.id,
    name,
    addressLine1,
    addressLine2,
    city,
    countryCode,
    postCode,
    phoneNumber,
    setValue,
    sourcePrefix,
  ]);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.name`}
        label={`${type} name`}
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.address.addressLine1`}
        label="Address Line One"
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.address.addressLine2`}
        label="Address Line Two"
        fullWidth
      />
      <TextInput
        sx={inputStyles}
        source={`${sourcePrefix}.address.city`}
        label="City"
        fullWidth
      />
      <TextInput
        source={`${sourcePrefix}.address.postCode`}
        sx={inputStyles}
        label="Post Code"
        fullWidth
      />
      <SelectInput
        inputProps={{ "data-testid": `${sourcePrefix}.address.countryCode` }}
        source={`${sourcePrefix}.address.countryCode`}
        sx={inputStyles}
        label="Country"
        choices={countrySelectChoices}
        fullWidth
      />
      <EmailInput source={`${sourcePrefix}.email`} sx={inputStyles} fullWidth />
      <TextInput
        source={`${sourcePrefix}.phoneNumber`}
        sx={inputStyles}
        label="Phone Number"
        fullWidth
      />
    </div>
  );
}
