import React, { useState } from "react";
import { useGetOne, BooleanInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useFlag } from "../../featureFlags";
import { TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP } from "../../featureFlags/flags";
import { HealthmailAddressFormInputs } from "./HealthmailAddress/HealthmailAddressFormInputs";
import { GooglePlaceAddressForm } from "./GooglePlace/GooglePlaceAddressForm";
import { Grid } from "@mui/material";

export function PharmacyAddressInput() {
  const isHealthmailLookupEnabled = useFlag(
    TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP
  );
  const [isHealthmailEnabled, setIsHealthmailEnabled] = useState(true);

  const form = useFormContext();
  const standaloneClientId = useWatch({
    name: "clientId",
    defaultValue: form.getValues("clientId"),
  });
  const clientsArray = useWatch({
    name: "clients",
    defaultValue: form.getValues("clients") || [],
  });
  const selectedClientId = standaloneClientId || clientsArray?.[0]?.id || "";

  const shouldFetchClient = Boolean(selectedClientId);
  const { data: client } = useGetOne(
    "clients",
    { id: selectedClientId },
    {
      enabled: shouldFetchClient,
      refetchOnWindowFocus: false,
    }
  );

  const irishCountryCode = "IE";
  const clientCountryCode = client?.attributes?.countryCode;
  const isHealthmailAddressRequired =
    isHealthmailLookupEnabled && clientCountryCode === irishCountryCode;

  return (
    <>
      {isHealthmailAddressRequired ? (
        <>
          <Grid item xs={12}>
            <BooleanInput
              source="useHealthmail"
              label="Healthmail"
              onChange={event => setIsHealthmailEnabled(event.target.checked)}
              defaultValue={true}
            />
          </Grid>

          {isHealthmailEnabled ? (
            <HealthmailAddressFormInputs source="nominatedPharmacy" />
          ) : (
            <Grid item xs={12} sm={6}>
              <GooglePlaceAddressForm
                sourcePrefix="nominatedPharmacy"
                type="Pharmacy"
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12} sm={6}>
          <GooglePlaceAddressForm
            sourcePrefix="nominatedPharmacy"
            type="Pharmacy"
          />
        </Grid>
      )}
    </>
  );
}
