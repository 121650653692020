import React from "react";
import { BooleanInput } from "ra-ui-materialui";
import { useFormContext, useWatch } from "react-hook-form";
import { EditTabProps } from "../../../types/utilities";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { defaultEmployerSettings } from "../../../constants/employer";
import { EmployerTableInput } from "../inputs/EmployerTableInput";
import { EmployersFileInput } from "../inputs/EmployersFileInput";
import { ListNewEmployers } from "../fields/ListNewEmployers";
import { DownloadExistingEmployersButton } from "../buttons/DownloadExistingEmployersButton";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";
import { Box } from "@mui/system";

const otherOptionSource = "employerSettings.attributes.allowOtherEmployeeOption";


export function EmployersEditTab(props: EditTabProps) {
  const form = useFormContext();
  const shouldDisplay = useLazyLoadEditTab(props.path);
  const employersEnabled = useWatch({ name: "employerSettings.attributes.employerRequiredOnBookings" });

  const resetOtherOption = () => {
    form.setValue(otherOptionSource, defaultEmployerSettings.attributes.allowOtherEmployeeOption);
  };

  return (
    <FormTab {...props} label="Employers" alignItems="normal">
      {
        !shouldDisplay ? null :
          <Box>
            <Heading level={2}>Employers Configuration</Heading>
            <BooleanInput
              source="employerSettings.attributes.employerRequiredOnBookings"
              label="Should capture employer"
              onChange={() => {
                resetOtherOption();
              }}
            />
            {employersEnabled ?
              <Box>
                <BooleanInput
                  source={otherOptionSource}
                  label="Allow 'other' option"
                />
                <EmployerTableInput />
                <ListNewEmployers />
                <EmployersFileInput />
                <DownloadExistingEmployersButton />
              </Box> : null
            }
          </Box>
      }
    </FormTab>
  );
}
