import React, { useEffect, useState } from "react";
import { useChoicesContext, useNotify } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { SignpostingSymptomService } from "../../types/symptomSignposting";

export interface SpecialtyInputProps {
  clinicalProductsEnabled: boolean;
}


export const SpecialtyInput = ({clinicalProductsEnabled}: SpecialtyInputProps) => {
  const { availableChoices, isLoading } = useChoicesContext();
  const { setValue } = useFormContext();
  const signpostingService = useWatch({ name: "signpostingService" });
  const [isComponentInitialised, setIsComponentInitialised] = useState<boolean>(false);
  const notify = useNotify();


  useEffect(() => {
    if (!isLoading && !isComponentInitialised) {
      if (availableChoices?.length === 1) {
        setValue("service", availableChoices[0].id);
      }
      if (!availableChoices?.length && clinicalProductsEnabled) {
        notify("No services available for this patient, check the clinical products settings for the client");
      }
      setIsComponentInitialised(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (signpostingService) {
      const serviceId = mapSignpostingServiceToSpecialityId(signpostingService, availableChoices);

      if (serviceId) {
        setValue("service", serviceId);
      }
    }
  }, [signpostingService]);

  const consultationType = useWatch({ name: "consultationType" });

  const handleSelectSpecialty = (
    value: string | number
  ) => {
    const selectedService = availableChoices?.find((s: any) => s.id === value);

    if (clinicalProductsEnabled && selectedService) {
      setValue("allowanceLeft", selectedService.allowanceLeft);
    }

    const isMatch = selectedService?.consultationTypes?.includes(consultationType);
  
    if (!isMatch) {
      setValue("consultationType", "");
    }
  };

  if (isLoading) return null;

  return (
    <AutocompleteInput
      name={"service"}
      required
      label="Service"
      optionText="name"
      onChange={handleSelectSpecialty}
      filterToQuery={query => ({
        name: query,
      })}
      groupBy={(choice) => choice.group}
    />
  );
};

function mapSignpostingServiceToSpecialityId(signpostingService: SignpostingSymptomService, availableServices: any[]) {
  if (!signpostingService || !availableServices)  {
    return;
  }
  if (signpostingService.attributes.specialty) {
    return availableServices.find(x => x.id === signpostingService.attributes.specialty)?.id;
  }

  if (signpostingService.attributes.clinicalServiceId) {
    return availableServices.find(x => x.id === signpostingService.attributes.clinicalServiceId)?.id;
  }
}
