import React, { useState } from "react";
import {
    Button,
    Confirm,
    Labeled,
    useCreate,
    useNotify,
    useRecordContext,
    useGetList,
    useRefresh,
  } from "react-admin";
import RefundIcon from "@mui/icons-material/Payment";
import { Heading } from "../../../ui/Heading";
import { Grid, Tooltip } from "@mui/material";
import { Group } from "../../../layout/Group";
import { BooleanField } from "../../../fields/BooleanField";
import get from "lodash/get";
import { PatientLinkField } from "../../PatientLinkField";
import dayjs from "dayjs";

export function AllowanceDetails() {   
    return (
      <Group heading={<Heading level={2}>Payments details</Heading>}>
        <Grid item xs={12} sm={6}>
          <PatientLinkField 
            label="Payment Type" 
            tab="product" 
            textContent="Inclusive allowance" 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label="Refunded">
            <BooleanField source="attributes.payload.IsRefundRequested" emptyText="No" />
          </Labeled>
        </Grid>
        <RefundAllowanceButton />
      </Group>
    );
  }
  
function RefundAllowanceButton() {
    const record = useRecordContext();
    const refresh = useRefresh();

    const [isDialogOpen, setDialogOpenState] = useState(false);
    const [isRefundRequestSent, setRefundRequestSent] = useState(false);
    const notify = useNotify();

    const { data: patientClinicalServices } = useGetList("patientClinicalServices",
        {
            filter: { clientId: record.clients[0].id, patientRecordId: record.users[0].id },
        }
    );

    const [refundAllowance, { isLoading }] = useCreate("refundAllowance", 
        {
            data: {
                clientId: record.clients[0].id,
                bookingId: record.bookingId
            },
        },
        {
            onSuccess: () => {
                setDialogOpenState(false);
                setRefundRequestSent(true);
                refresh();
                notify("Allowance refund has been requested");
            },
            onError: () => {
                setDialogOpenState(false);
                notify("Something went wrong while processing refund");
            }
        }
    );

    const {buttonDisabled, tooltipMessage} = CalculateRefundButtonState(
        isRefundRequestSent, 
        isLoading,
        patientClinicalServices ?? []
    );

    return (
        <>
            <Confirm
                isOpen={isDialogOpen}
                title="Refund allowance"
                content="Are you sure you want to refund this user's allowance?"
                confirm="Yes"
                confirmColor="primary"
                cancel="Cancel"
                onConfirm={() => refundAllowance()}
                onClose={() => setDialogOpenState(false)}
            />
            <Tooltip title={tooltipMessage}>
                <span>
                <Button
                    sx={{margin: 1}}
                    disabled={buttonDisabled}
                    label="Refund Allowance"
                    variant="outlined"
                    onClick={() => setDialogOpenState(true)}
                >
                    <RefundIcon />
                </Button>
                </span>
            </Tooltip>
        </>
    );
}
  
function CalculateRefundButtonState(
    refundRequestSent: boolean,
    isLoading: boolean,
    patientClinicalServices: any[]
): { buttonDisabled: boolean, tooltipMessage: string } 
{
    const record = useRecordContext();
    const payload = get(record, "attributes.payload");

    if (payload.IsRefundRequested) {
        return { buttonDisabled: true, tooltipMessage: "Allowance for this booking has already been refunded" }
    }

    if (refundRequestSent || isLoading) {
        return { buttonDisabled: true, tooltipMessage: "Refund allowance request in progress" }
    }

    const bookingService = record?.clinicalServices?.[0]?.attributes?.name ?? record?.appointments?.[0]?.attributes?.specialties[0];

    const mappedClinicalProductDetails = patientClinicalServices
        .map(el => ({
            service: el.clinicalServices?.[0]?.attributes?.name ?? el.attributes?.specialty,
            renewalDate: el.clinicalProducts?.[0]?.attributes?.nextRenewalDate,
            renewalEvery: el.clinicalProducts?.[0]?.attributes?.maxUsageTimeFrame,
            unlimitedAllowance: el.attributes?.allowanceType?.toLowerCase() === 'unlimited'
        }))
        .filter(el => el.service === bookingService);

    if (!mappedClinicalProductDetails.length) {
        return { buttonDisabled: true, tooltipMessage: "Service for this booking is no longer available under the patient's current product services" };
    }

    const clinicalProductDetails = mappedClinicalProductDetails[0];

    if (clinicalProductDetails.unlimitedAllowance) {
        return { buttonDisabled: true, tooltipMessage: "Service is configured for unlimited usage" };
    }

    if (clinicalProductDetails.renewalEvery && clinicalProductDetails.renewalDate) {
        const renewalDate = dayjs(clinicalProductDetails.renewalDate);
        const currentYear = dayjs().year();
        const formattedMonth = clinicalProductDetails.renewalEvery.month.toString().padStart(2, '0');
        const formattedDay = clinicalProductDetails.renewalEvery.day.toString().padStart(2, '0');
        let previousRenewalDate = dayjs(`${currentYear}-${formattedMonth}-${formattedDay}`, 'YYYY-MM-DD');

        if (previousRenewalDate.isAfter(renewalDate) || previousRenewalDate.isSame(renewalDate)) {
            previousRenewalDate = previousRenewalDate.subtract(1, 'year');
        }

        const appointmentEndDate = dayjs(record?.appointments?.[0]?.attributes?.end);
        
        if (appointmentEndDate.isBefore(previousRenewalDate)) {
            return { buttonDisabled: true, tooltipMessage: "Consultation did not take place in current allowance window" };
        }
    }

    return { buttonDisabled: false, tooltipMessage: "Note: this action will refund an allowance for the clinical product associated with this booking" };
}