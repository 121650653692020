import React from "react";
import { FieldValues } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { BooleanInput, Form, FormDataConsumer, NumberInput, TextInput } from "react-admin";
import { ArrayInput } from "../../inputs/ArrayInput";
import { FormIteratorAddButton } from "../../form/FormIteratorAddButton";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import { FormIteratorRemoveButton } from "../../form/FormIteratorRemoveButton";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { nonEmpty } from "../../../validators/nonEmpty";
import { areArraysEqual } from "../../../utilities/areArraysEqual";
import { Heading } from "../../ui/Heading";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { SelectInput } from "ra-ui-materialui";
import { required } from "ra-core";
import { allowanceRenewalFrequencies } from "../../../constants/constants";
import get from "lodash/get";

export type DialogMode = "create" | "edit";

export interface ClinicalProductDialogProps {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  submitButtonText: string;
  record: any;
  submit: (values: FieldValues) => void;
  mode: DialogMode
}

export const BaseClinicalProductDialog = (props: ClinicalProductDialogProps) => {
  const { isOpened, onClose, title, submitButtonText, record, submit, mode } = props;

  const clinicalProductNameFilterStyles = {
    "& .MuiFormHelperText-root": {
      display: "none"
    }
  };

  const headingStyles = {
    display: "flex",
    alignItems: "center",
    width: "100%",
  };

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      data-cy="clincalProduct-dialog"
    >
      <DialogTitle>
        <Typography variant="h2" sx={{ margin: 0 }}>
          {title}
        </Typography>
      </DialogTitle>
      <Form record={record} onSubmit={submit}>
        <DialogContent>
          <Stack>
            <TextInput
              required
              source="name"
              label="Name"
              validate={nonEmpty("Name is required")}
            />
            <TextInput
              required
              source="code"
              label="Code"
              inputProps={{ maxLength: 10 }}
              disabled={mode === "edit"}
              validate={nonEmpty("Code is required")}
            />
            <BooleanInput
              label="Is Enabled"
              source="isEnabled"
            />

              <Heading level={3} sx={headingStyles}>
                Allowance Renewal Date
                <TooltipAdornment
                  text={
                    "Annual date when patient allowances are reset. Cannot be updated if the product is in use by patients."
                  }
                />
              </Heading>
              <SelectInput
                sx={{ width: "220px" }}
                label="Allowance Renewal Frequency"
                source="allowanceRenewalFrequency"
                choices={allowanceRenewalFrequencies}
                validate={required("Allowance renewal frequency is required")}
              />
            <FormDataConsumer>
              {({ formData }) => {
                const allowanceRenewalFrequency = get(formData, "allowanceRenewalFrequency");

                const displayRenewalDateInput = allowanceRenewalFrequency === "AnnualFixedDate";

                if(!displayRenewalDateInput) return null;

                return (
                  <Box>
                    <NumberInput
                      required
                      sx={{ maxWidth: "120px" }}
                      label="Day"
                      source="maxUsageTimeFrame.day"
                      min={1}
                      max={31}
                    />
                    <NumberInput
                      required
                      sx={{ marginLeft: "10px", maxWidth: "120px" }}
                      label="Month"
                      source="maxUsageTimeFrame.month"
                      min={1}
                      max={12}
                    />
                  </Box>
                );
              }}
            </FormDataConsumer>

            <Heading
              sx={headingStyles}
              level={3}
            >
              Clinical Product Services
              <TooltipAdornment
                text={
                  "Defining a maximum usage count for a service prevents new bookings once the limit is reached. Leave blank to allow unlimited bookings."
                }
              />
            </Heading>
            <ArrayInput
              level={3}
              source="clinicalProductServices"
            >
                <SimpleFormIterator
                  addButton={<FormIteratorAddButton label="Add service" />}
                  removeButton={<FormIteratorRemoveButton label="Remove service" />}
                >
                  <FormDataConsumer>
                    {({ formData, scopedFormData, getSource }) => {
                      if (!getSource) return null;

                      const selectedServiceIds = formData?.clinicalProductServices?.map((service:any) => service.id) || [];

                      const availableChoices = record.appointmentFilterCategories?.filter(
                        (category:any) => !selectedServiceIds.includes(category.id) || scopedFormData?.id === category.id
                      );

                      const handleChange = (event:any) => {
                        scopedFormData["name"] = availableChoices.find((x:any) => x.id === event).name
                      };

                      return (
                        <Box>
                          <AutocompleteInput
                            required
                            validate={nonEmpty("Select a specialty or clinical service")}
                            source={getSource("id")}
                            sx={{ display: "inline-block", marginRight: "20px" }}
                            choices={availableChoices}
                            label="Specialty / Clinical Service"
                            optionText="name"
                            filterToQuery={query => ({
                              name: query,
                            })}
                            groupBy={(choice) => choice.group}
                            onChange={handleChange}
                          />
                          <NumberInput
                            sx={clinicalProductNameFilterStyles}
                            source={getSource("maxUsageCount")}
                            label={"Maximum Usage Count"}
                          />
                        </Box>
                      );
                    }}
                  </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>
        </DialogContent>
        <FormDataConsumer>
          {({ formData }) => {
            // eslint-disable-next-line lodash/prefer-matches
            const isSubmitDisabled = record.name === formData.name &&
              record.isEnabled === formData.isEnabled &&
              record.allowanceRenewalFrequency === formData.allowanceRenewalFrequency &&
              record.maxUsageTimeFrame?.day === formData.maxUsageTimeFrame?.day &&
              record.maxUsageTimeFrame?.month === formData.maxUsageTimeFrame?.month &&
              areArraysEqual(record.clinicalProductServices?.map((x:any) => `${x.id}:${x.name}:${x.maxUsageCount}`), formData.clinicalProductServices?.map((x:any) => `${x.id}:${x.name}:${x.maxUsageCount}`))
            return (
              <DialogActions>
                <Button onClick={onClose} aria-label="Cancel">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" disabled={isSubmitDisabled} data-testid={"submitChangeClinicalProductButton"}>
                  {submitButtonText}
                </Button>
              </DialogActions>
            );
          }}
        </FormDataConsumer>
      </Form>
    </Dialog>
  );
};