import React from "react";
import { AutocompleteArrayInput, BooleanInput, TextInput } from "ra-ui-materialui";
import { ReferenceArrayInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { specialtyChoices } from "../../constants/constants";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { styled } from "@mui/material";

interface RegistrationNumberInputProps {
  labelPrefix: string,
  source: string,
  flagSource: string
}

const StyledWrapper = styled("div")(() => ({
  width: "300px",
}));

const specialityInputStyles = {
  width: "256px",
};

export function CPSpecificInputs() {
  const specialty = useWatch({name: "attributes.specialty"})

  if (specialty === "GeneralPractitioner" || specialty == "Psychiatrist") {
    return <GPInputs />;
  }

  if (specialty === "Physiotherapist"
    || specialty === "SpeechAndLanguage"
    || specialty === "Dietitian")
  {
    return <AhsInputs />;
  }

  if (specialty === "ClinicalPharmacist") {
    return <ClinicalPharmacistInputs />;
  }

  if (specialty === "Counsellor") {
    return <CounsellorInputs />;
  }

  if (specialty === "AdvancedNursePractitioner") {
    return <AdvancedNursePractitionerInputs />
  }

  if (specialty === "Psychologist") {
    return <PsychologistInputs />
  }

  if (specialty === "PhysicianAssociate") {
    return <PhysicianAssociateInputs />
  }

  return null;
}

export function CPSpecialityInput() {
  const form = useFormContext();
  const onSpecialityChange = () => {
    form.setValue("expertiseAreas", []);

    const cpRegistrations = form.getValues("cpRegistrations");
    if (cpRegistrations) {
      for (const key of Object.keys(cpRegistrations)) {
        cpRegistrations[key].isRegistered = false
      }
      form.setValue("cpRegistrations", cpRegistrations);
    }
  };

  return (
    <AutocompleteInput
      required
      source="attributes.specialty"
      label="Specialty"
      choices={specialtyChoices}
      onChange={onSpecialityChange}
      sx={specialityInputStyles}
    />
  );
}

export function CPExpertiseInput() {
  const specialty = useWatch({name: "attributes.specialty"})

  if (!specialty) return null;

  return (
    <ReferenceArrayInput
      reference="expertiseAreas"
      source="expertiseAreas"
      filter={{ specialty }}
    >
      <ExpertiseChoicesInput />
    </ReferenceArrayInput>
  );
}

function ExpertiseChoicesInput() {
  return (
    <AutocompleteArrayInput
      filterToQuery={(query: string) => ({
        name: query,
      })}
      source="id"
      label="Expertise"
      sx={specialityInputStyles}
      optionText="attributes.name"
    />
  );
}

function RegistrationNumberInput(props: RegistrationNumberInputProps) {
  const { labelPrefix, source, flagSource } = props;
  const isRegistered = useWatch({name: flagSource});
  return (
    <>
      <BooleanInput label={`${labelPrefix} Registered`} source={flagSource} helperText={false} data-testid={`${labelPrefix}-Toggle`}/>
      {isRegistered &&
        <TextInput
          label={`${labelPrefix} Number`}
          source={source}
          required
          data-testid={`${labelPrefix}-Input`}
        />
      }
    </>
  )
}

function GPInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="GMC"
        flagSource="cpRegistrations.GeneralMedicalCouncil.isRegistered"
        source="cpRegistrations.GeneralMedicalCouncil.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="IMC"
        flagSource="cpRegistrations.IrishMedicalCouncil.isRegistered"
        source="cpRegistrations.IrishMedicalCouncil.registrationNumber"
      />
    </StyledWrapper>
  );
}

function AhsInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="HCPC"
        flagSource="cpRegistrations.HealthAndCareProfessionsCouncil.isRegistered"
        source="cpRegistrations.HealthAndCareProfessionsCouncil.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="CORU"
        flagSource="cpRegistrations.CORU.isRegistered"
        source="cpRegistrations.CORU.registrationNumber"
      />
    </StyledWrapper>
  );
}

function ClinicalPharmacistInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="GPhC"
        flagSource="cpRegistrations.GeneralPharmaceuticalCouncil.isRegistered"
        source="cpRegistrations.GeneralPharmaceuticalCouncil.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="PSI"
        flagSource="cpRegistrations.PharmaceuticalSocietyOfIreland.isRegistered"
        source="cpRegistrations.PharmaceuticalSocietyOfIreland.registrationNumber"
      />
    </StyledWrapper>
  );
}

function CounsellorInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="BACP"
        flagSource="cpRegistrations.BritishAssociationForCounsellingAndPsychotherapy.isRegistered"
        source="cpRegistrations.BritishAssociationForCounsellingAndPsychotherapy.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="IACP"
        flagSource="cpRegistrations.IrishAssociationForCounsellingAndPsychotherapy.isRegistered"
        source="cpRegistrations.IrishAssociationForCounsellingAndPsychotherapy.registrationNumber"
      />
    </StyledWrapper>
  );
}

function AdvancedNursePractitionerInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="NMC"
        flagSource="cpRegistrations.NursingAndMidwiferyCouncil.isRegistered"
        source="cpRegistrations.NursingAndMidwiferyCouncil.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="NMBI"
        flagSource="cpRegistrations.NursingAndMidwiferyBoardOfIreland.isRegistered"
        source="cpRegistrations.NursingAndMidwiferyBoardOfIreland.registrationNumber"
      />
    </StyledWrapper>
  )
}

function PsychologistInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="BPS"
        flagSource="cpRegistrations.BritishPsychologicalSociety.isRegistered"
        source="cpRegistrations.BritishPsychologicalSociety.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="PSI"
        flagSource="cpRegistrations.PsychologicalSocietyOfIreland.isRegistered"
        source="cpRegistrations.PsychologicalSocietyOfIreland.registrationNumber"
      />
    </StyledWrapper>
  )
}

function PhysicianAssociateInputs() {
  return (
    <StyledWrapper>
      <RegistrationNumberInput
        labelPrefix="GMC"
        flagSource="cpRegistrations.GeneralMedicalCouncil.isRegistered"
        source="cpRegistrations.GeneralMedicalCouncil.registrationNumber"
      />
      <RegistrationNumberInput
        labelPrefix="ISPA"
        flagSource="cpRegistrations.IrishSocietyOfPhysicianAssociates.isRegistered"
        source="cpRegistrations.IrishSocietyOfPhysicianAssociates.registrationNumber"
      />
    </StyledWrapper>
  )
}