import { useGetList } from "ra-core";
import React, { createContext, useContext, useState } from "react";
import { HealthmailPharmacy } from "../types/healthmail";

interface Context {
  currentAddress: HealthmailPharmacy;
  addresses: HealthmailPharmacy[];
  selectAddress: (id: string) => void;
  requestName: (name: string | null) => void;
  requestAddress: (address: string | null) => void;
  requestCity: (city: string | null) => void;
  loading: boolean;
}

const defaultAddress: HealthmailPharmacy = {
  id: "",
  pharmacyId: "",
  formattedName: "",
  name: "",
  emailAddress: "",
  phoneNumber: "",
  address: {
    line: [],
    country: "",
    state: "",
    city: "",
    postalCode: "",
  },
};

const HealthmailAddressContext = createContext<Context>({
  currentAddress: defaultAddress,
  addresses: [],
  selectAddress: () => null,
  requestName: () => null,
  requestAddress: () => null,
  requestCity: () => null,
  loading: false,
});

interface ProviderProps {
  children: React.ReactNode;
}

export function HealthmailAddressContextProvider(props: ProviderProps) {
  const { children } = props;
  const [currentAddressId, setCurrentAddressId] = useState<string | null>(null);
  const [nameFilter, setNameFilter] = useState<string | null>(null);
  const [addressFilter, setAddressFilter] = useState<string | null>(null);
  const [cityFilter, setCityFilter] = useState<string | null>(null);

  const { data = [], isLoading } = useGetList<HealthmailPharmacy>(
    "gaia:pharmacies",
    {
      pagination: {
        page: 0,
        perPage: 0,
      },
      sort: {
        field: "id",
        order: "asc",
      },
      filter: {
        addressName: nameFilter,
        address: addressFilter,
        addressCity: cityFilter,
      },
    },
    {
      enabled:
        Boolean(nameFilter) || Boolean(addressFilter) || Boolean(cityFilter),
      refetchOnWindowFocus: false,
    }
  );

  const getCurrentAddress = () => {
    const current = currentAddressId
      ? data.find(d => d.pharmacyId == currentAddressId)
      : null;
    return current ?? defaultAddress;
  };

  const currentAddress = getCurrentAddress();

  return (
    <HealthmailAddressContext.Provider
      value={{
        currentAddress,
        addresses: data,
        selectAddress: setCurrentAddressId,
        requestName: setNameFilter,
        requestAddress: setAddressFilter,
        requestCity: setCityFilter,
        loading: isLoading,
      }}
    >
      {children}
    </HealthmailAddressContext.Provider>
  );
}

export const useHealthmailAddressContext = () =>
  useContext(HealthmailAddressContext);
