import { NonJsonApiCreate } from "..";


interface Params {
  start: string;
  end: string;
  clinicalPractitionerIds: string[];
  unitGroupIds: number[];
}

async function importShiftsStart(params: Params) {
  const endpoint = "/admin/shifts/import";


  const { importedShifts, ignoredCount } = await NonJsonApiCreate(endpoint, params);

  return {
    data: {
      id: "id-to-satisfy-ra",
      shifts: importedShifts,
      ignoredCount
    }
  }

}


export default importShiftsStart;
