import { GooglePlacesAddress } from "../components/inputs/GooglePlace/GooglePlaceAddressInputs";

export function splitAddress(addressComponents: GooglePlacesAddress): {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postCode: string;
} {
  const { name, address, vicinity, city, countryCode, postCode } =
    addressComponents;

  const addressParts = [
    ...(address ? address.split(",").map(p => p.trim()) : []),
    ...(vicinity ? vicinity.split(",").map(p => p.trim()) : []),
  ]
    .filter(Boolean)
    .reduce(
      (result, part) => {
        const lower = part.toLowerCase();
        if (
          !result.set.has(lower) &&
          lower !== (city || "").toLowerCase() &&
          lower !== (name || "").toLowerCase() &&
          lower !== (postCode || "").toLowerCase()
        ) {
          result.set.add(lower);
          result.parts.push(part);
        }
        return result;
      },
      { set: new Set<string>(), parts: [] as string[] }
    ).parts;

  for (let i = 0; i < addressParts.length - 1; i++) {
    if (/^\d+$/.test(addressParts[i])) {
      addressParts[i] = `${addressParts[i]} ${addressParts[i + 1]}`.trim();
      addressParts.splice(i + 1, 1);
      i--;
    }
  }

  const getWords = (s: string) => s.toLowerCase().split(/\s+/).filter(Boolean);

  outer: for (let i = 0; i < addressParts.length; i++) {
    for (let j = i + 1; j < addressParts.length; j++) {
      const wordsA = getWords(addressParts[i]);
      const wordsB = getWords(addressParts[j]);

      const bInA = wordsB.every(w => wordsA.includes(w));
      const aInB = wordsA.every(w => wordsB.includes(w));

      if (bInA) {
        addressParts.splice(j, 1);
        j--;
      } else if (aInB) {
        addressParts.splice(i, 1);
        i--;
        continue outer;
      }
    }
  }

  while (addressParts.length > 2) {
    const last = addressParts.pop();
    addressParts[addressParts.length - 1] += `, ${last}`;
  }

  const [addressLine1 = "", addressLine2 = ""] = addressParts;

  return {
    addressLine1,
    addressLine2,
    city: city || "",
    country: countryCode || "",
    postCode: postCode || "",
  };
}
