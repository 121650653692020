import { update } from "../index";

async function updateClinicalService(args: any) {
  const { id, type } = args.data;
  const {
    name,
    appointmentDuration,
    shiftTypeId,
    deliveryType,
    notificationsEnabled,
    pmsCode,
    canBeReleased,
    releaseThreshold,
    fallbackServiceId,
    recordingEnabled,
  } = args.data.attributes;

  const endpoint = `/ms/clinicalServices/${id}`;


  const body = {
    data: {
      type,
      id,
      attributes: {
        name,
        deliveryType,
        shiftTypeId: shiftTypeId ? shiftTypeId : null,
        appointmentDuration: deliveryType === "Synchronous" ? appointmentDuration : null,
        pmsCode,
        notificationsEnabled,
        canBeReleased,
        releaseThreshold,
        fallbackServiceId: fallbackServiceId ? fallbackServiceId : null,
        recordingEnabled,
      },
    }
  };

  return await update(endpoint, body);
}

export default updateClinicalService;
