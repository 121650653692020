import React from "react";
import { Grid } from "@mui/material";
import {
  TextField,
  Labeled,
  useRecordContext,
  BooleanInput,
} from "react-admin";
import { EMPTY_TEXT } from "../../../../constants/constants";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { PharmacyAddressField } from "../../../fields/PharmacyAddressField";
import { GooglePlaceAddressForm } from "../../../inputs/GooglePlace/GooglePlaceAddressForm";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";
import { useFlag } from "../../../../featureFlags";
import { TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP } from "../../../../featureFlags/flags";
import { HealthmailAddressFormInputs } from "../../../inputs/HealthmailAddress/HealthmailAddressFormInputs";
import { VerifiedPharmacyAddressField } from "../../../fields/VerifiedPharmacyAddressField";
import { useFormContext } from "react-hook-form";
import { Pharmacy } from "../../../../types/patients";

const nominatedPharmacySource = "attributes.nominatedPharmacy";

export function PharmacyDetails() {
  const { currentState } = useEditStateContext();
  const record = useRecordContext();

  const isHealthmailLookupEnabled = useFlag(
    TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP
  );
  const irishCountryCode = "IE";
  const isHealthmailAddressRequired =
    isHealthmailLookupEnabled &&
    record.clients[0].attributes.countryCode == irishCountryCode;

  if (currentState === "show")
    return (
      <ShowContent
        isHealthmailSelectFeatureEnabled={isHealthmailAddressRequired}
      />
    );

  return (
    <EditContent
      isHealthmailSelectFeatureEnabled={isHealthmailAddressRequired}
    />
  );
}

interface ContentProps {
  isHealthmailSelectFeatureEnabled: boolean;
}

function ShowContent(props: ContentProps) {
  return (
    <Group heading={<Heading level={2}>Nominated Pharmacy Details</Heading>}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <Labeled label="Pharmacy Name">
            <TextField
              source={getNominatedPharmacyPropertySource("name")}
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="Pharmacy Phone Number">
            <TextField
              source={getNominatedPharmacyPropertySource("phoneNumber")}
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
          <Labeled label="Pharmacy Email">
            <TextField
              source={getNominatedPharmacyPropertySource("email")}
              emptyText={EMPTY_TEXT}
            />
          </Labeled>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {props.isHealthmailSelectFeatureEnabled ? (
          <HealthmailAddressField />
        ) : (
          <PharmacyAddressField source={nominatedPharmacySource} />
        )}
      </Grid>
    </Group>
  );
}

function getNominatedPharmacyPropertySource(property: string) {
  return `${nominatedPharmacySource}.${property}`;
}

function HealthmailAddressField() {
  const record = useRecordContext();
  const pharmacy = record.attributes?.nominatedPharmacy;
  const isPharmacyVerified = Boolean(pharmacy?.organisationId);

  return (
    <VerifiedPharmacyAddressField
      label="Pharmacy Address"
      isVerifiedPharmacy={isPharmacyVerified}
      pharmacy={pharmacy}
    />
  );
}

const isSelectingHealthmailPharmacySource = "isSelectingHealthmailPharmacy";

function EditContent(props: ContentProps) {
  const { setValue } = useFormContext();

  const resetInputAddress = () => {
    const mappedPharmacy: Pharmacy = {
      organisationId: "",
      name: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        postCode: "",
        countryCode: "",
      },
      phoneNumber: "",
      email: "",
    };

    setValue(nominatedPharmacySource, mappedPharmacy, { shouldDirty: true });
  };

  return (
    <Group heading={<Heading level={2}>Nominated Pharmacy Details</Heading>}>
      {props.isHealthmailSelectFeatureEnabled && (
        <Grid item xs={12}>
          <BooleanInput
            source={isSelectingHealthmailPharmacySource}
            label="Healthmail"
            aria-label="Select Healthmail Address Type"
            helperText=""
            defaultValue={true}
            onClick={resetInputAddress}
            data-testid="select-healthmail-address-type"
          />
        </Grid>
      )}

      <AddressControl {...props} />
    </Group>
  );
}

function AddressControl(props: ContentProps) {
  const { watch } = useFormContext();
  const isSelectingHealthmailAddress = watch(
    isSelectingHealthmailPharmacySource,
    true
  );

  return (
    <>
      {props.isHealthmailSelectFeatureEnabled &&
      isSelectingHealthmailAddress ? (
        <HealthmailAddressFormInputs source={nominatedPharmacySource} />
      ) : (
        <GooglePlaceAddressForm
          sourcePrefix={nominatedPharmacySource}
          type="Pharmacy"
        />
      )}
    </>
  );
}
