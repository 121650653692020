import React from "react";
import { Heading } from "../ui/Heading";
import { BooleanInput } from "react-admin";
import TooltipAdornment from "./TooltipAdornment";
import { GooglePlaceAddressForm } from "../inputs/GooglePlace/GooglePlaceAddressForm";
import { Group } from "../layout/Group";
import { Grid } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { PharmacyAddressInput } from "../inputs/PharmacyAddressInput";

const headingStyles = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export function ClinicalAddressesInputs() {
  const form = useFormContext();
  const showGpDetails = useWatch({
    name: "showGpDetails",
    control: form.control,
  });
  const showPharmacyDetails = useWatch({
    name: "showPharmacyDetails",
    control: form.control,
  });

  return (
    <>
      <Group
        heading={
          <Heading level={2} sx={headingStyles}>
            GP Details
            <TooltipAdornment
              text={
                "The patient’s GP needs to know about consultation notes for any ongoing care they may support."
              }
            />
          </Heading>
        }
      >
        <Grid item xs={12}>
          <BooleanInput source="showGpDetails" label="Show GP Details" />
        </Grid>
        <Grid item xs={12} sm={6}>
          {showGpDetails ? (
            <GooglePlaceAddressForm
              sourcePrefix="gp.surgery"
              type="GP Surgery"
            />
          ) : null}
        </Grid>
      </Group>
      <Group
        heading={
          <Heading level={2} sx={headingStyles}>
            Pharmacy Details
            <TooltipAdornment
              text={
                "By nominating a pharmacy, the patient can have any prescription issued much faster. There is no need to contact them again."
              }
            />
          </Heading>
        }
      >
        <Grid item xs={12}>
          <BooleanInput
            source="showPharmacyDetails"
            label="Show Pharmacy Details"
          />
        </Grid>
        {showPharmacyDetails ? <PharmacyAddressInput /> : null}
      </Group>
    </>
  );
}
