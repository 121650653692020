import React, { useEffect, useState } from "react";
import { Box, Button, Chip } from "@mui/material";
import { FunctionField, ListContextProvider, RaRecord, TextInput, useGetList, useList } from "react-admin";
import debounce from "lodash/debounce";
import { ClinicalProductsChange, ClinicalProductsDTO } from "../../../types/clinicalProducts";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { ArrayField, Datagrid, SingleFieldList, TextField } from "ra-ui-materialui";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import { BooleanField } from "../../fields/BooleanField";
import { ClinicalProductCreateDialog } from "../dialogs/ClinicalProductCreateDialog";
import { v4 as uuid } from "uuid";
import { ChangeAction } from "../../../types/common";
import { ClinicalProductChangesTableInput } from "./ClinicalProductChangesTableInput";
import { ClinicalProductEditDialog } from "../dialogs/ClinicalProductEditDialog";
import { useRecordContext } from "ra-core";
import { areArraysEqual } from "../../../utilities/areArraysEqual";
import { getStringDate } from "../helpers/getStringDate";
import { splitByCapitalLetters } from "../../../utilities/splitByCapitalLetters";

export function ClinicalProductTableInput() {
  const clientId = useWatch({name: "id"});
  const record = useRecordContext();

  const { setValue, formState } = useFormContext();

  const [shouldReplaceResponse, setShouldReplaceResponse] = useState(false);
  const [filterState, setFilterState] = useState({ page: 1, name: "" });

  const [changesList, setChangesList] = useState<ClinicalProductsChange[]>([]);
  const [listOfClinicalProducts, setListOfClinicalProducts] = useState<ClinicalProductsDTO["data"][]>([]);
  const listContext = useList<ClinicalProductsDTO["data"]>({ data: listOfClinicalProducts });

  const {isLoading, pageInfo } = useGetList(
    "clinicalProducts",
    {
      pagination: { page: filterState.page, perPage: 25 },
      filter: { clientId, name: filterState.name },
      sort: { field: "name", order: "ASC" }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        if (shouldReplaceResponse) {
          setListOfClinicalProducts(data!);
        } else {
          setListOfClinicalProducts([...listOfClinicalProducts, ...data!]);
        }
      }
    }
  );

  useEffect(() => {
    setChanges([]);
    setFilterState({page: 1, name: ''})
    setShouldReplaceResponse(true);

  }, [formState.isSubmitSuccessful]);
  
  const onFilterChange = debounce((e: any) => {
    setShouldReplaceResponse(true);
    setFilterState({ page: 1, name: e.target.value });
  }, 600);

  const loadMoreItems = () => {
    if (!isLoading) {
      setFilterState({ ...filterState, page: filterState.page + 1 });
      setShouldReplaceResponse(false);
    }
  };

  const setChanges = (updatedList: ClinicalProductsChange[]) => {
    setChangesList(updatedList);
    setValue("clinicalProductsChanges", updatedList, { shouldDirty: true });
  };


  const addClinicalProduct = (values: FieldValues) => {
    const clinicalProductServices = values.clinicalProductServices.filter((x:any) => x.id);
    const newClinicalProduct = {
      id: uuid(),
      attributes: {
        ...values,
        clientId,
        services: clinicalProductServices,
        clinicalProductServices: clinicalProductServices.map((x:any) => ({
          clinicalServiceId: !isNaN(x.id) ? x.id : undefined,
          specialty: isNaN(x.id) ? x.id : undefined,
          maxUsageCount: x.maxUsageCount,
        }))
      }
    } as ClinicalProductsDTO["data"];

    setChanges([...changesList, { id: newClinicalProduct.id, action: ChangeAction.Added, payload: newClinicalProduct }]);
  };

  // eslint-disable-next-line
  const updateClinicalProduct = (id: string, values: FieldValues) => {
    const clinicalProduct = listOfClinicalProducts.find(r => r.id === id)!;
    const clinicalProductServices = values.clinicalProductServices.filter((x:any) => x.id);

    const newClinicalProduct = {
      ...clinicalProduct,
      attributes: {
        ...clinicalProduct.attributes,
        ...values,
        services: clinicalProductServices,
        clinicalProductServices: clinicalProductServices.map((x:any) => ({
          clinicalServiceId: !isNaN(x.id) ? x.id : undefined,
          specialty: isNaN(x.id) ? x.id : undefined,
          maxUsageCount: x.maxUsageCount,
        }))
      }
    }

    const alreadyChanged = changesList.find(r => r.payload.id === id);

    if (!alreadyChanged) {
      setChanges([...changesList, { id, action: ChangeAction.Edited, payload: newClinicalProduct, oldPayload: clinicalProduct }]);

      // Remove from the changes list if edited and initial values are the same
    } else if (alreadyChanged.oldPayload &&
      alreadyChanged.oldPayload.attributes.name === values.name &&
      alreadyChanged.oldPayload.attributes.isEnabled === values.isEnabled &&
      alreadyChanged.oldPayload.attributes.allowanceRenewalFrequency === values.allowanceRenewalFrequency &&
      alreadyChanged.oldPayload.attributes.maxUsageTimeFrame?.day === values.maxUsageTimeFrame?.day &&
      alreadyChanged.oldPayload.attributes.maxUsageTimeFrame?.month === values.maxUsageTimeFrame?.month &&
      areArraysEqual(alreadyChanged.oldPayload.attributes.services?.map(x => `${x.id}:${x.name}:${x.maxUsageCount}`), newClinicalProduct.attributes.services?.map((x:any) => `${x.id}:${x.name}:${x.maxUsageCount}`))) {
      setChanges(changesList.filter(r => r.id !== id));

      // Set action as edited
    } else {
      setChanges([...changesList.filter(r => r.id !== id), {id, action: ChangeAction.Edited, payload: newClinicalProduct, oldPayload: clinicalProduct }]);
    }
  }

  const onUndoClicked = (change: ClinicalProductsChange) => setChanges(changesList.filter(r => r.id !== change.id));

  const onResetChangesClicked = () => setChanges([]);

  const clinicalProductNameFilterStyles = {
    "& .MuiFormHelperText-root": {
      display: "none"
    }
  };

  const styles = {
    "& .RaBulkActionsToolbar-toolbar": {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .RaDatagrid-row td:last-child": {
      textAlign: "right"
    }
  };

  return (
    <Box>
      <TextInput
        data-testid={"clinicalProductNameFilter"}
        onChange={onFilterChange}
        label={"Clinical Product Name"}
        source={"nameFilter"}
        name={"clinicalProductNameFilter"}
        value={filterState.name}
        sx={clinicalProductNameFilterStyles}
      />
      <Box sx={{ marginTop: "3rem", maxHeight: "30rem", overflow: "auto" }}>
        <ListContextProvider value={listContext}>
          <Datagrid
            bulkActionButtons={false}
            sx={styles}
            data-cy="clinical-products-edit-table"
            empty={<NoRecordsMessage message={"No clinical products are associated with this client. Click 'Add' to create one."} />}
          >
            <TextField
              label="Name"
              source="attributes.name"
              sortable={false}
            />
            <TextField
              label="Code"
              source="attributes.code"
              sortable={false}
            />
            <BooleanField
              label="Enabled"
              source="attributes.isEnabled"
              sortable={false}
            />
            <FunctionField
              render={(rec: RaRecord) => {
                return splitByCapitalLetters(rec.attributes.allowanceRenewalFrequency)
              }}
              label="Allowance Renewal Frequency"
              sortable={false}
            />
            <FunctionField
              render={(rec: RaRecord) => {
                if (!rec.attributes.maxUsageTimeFrame) return "N/A";

                const {day, month} = rec.attributes.maxUsageTimeFrame;
                return getStringDate(day, month)
              }}
              label="Allowance Renewal Date"
              emptyText="Not specified"
              hideLabel
              sortable={false}
            />
            <ArrayField label={"Services"} source="attributes.clinicalProductServices" sortable={false}>
              <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                <FunctionField label="Services" render={(rec: any) =>
                  <Chip
                    sx={{margin: "4px" }}
                    label={`${rec.specialty ?? rec.clinicalService.name} - ${rec.maxUsageCount ?? "Unlimited"}`}
                  />
                }/>
              </SingleFieldList>
            </ArrayField>
            <ClinicalProductEditDialog
                appointmentFilterCategories={record.appointmentFilterCategories}
                updateClinicalProduct={updateClinicalProduct}
            />
          </Datagrid>
          <Box display="flex" justifyContent="center">
            <Button
              data-testid={"LoadMoreButton"}
              sx={{ marginTop: "10px" }}
              onClick={loadMoreItems}
              variant="outlined"
              size="large"
              disabled={isLoading || !pageInfo?.hasNextPage}
            >
              Load more
            </Button>
          </Box>
        </ListContextProvider>
      </Box>
      <ClinicalProductChangesTableInput
        onResetChangesClicked={onResetChangesClicked}
        onUndoClicked={onUndoClicked}
        data={changesList}
      />
      <ClinicalProductCreateDialog addClinicalProduct={addClinicalProduct} />
    </Box>
  )
}
