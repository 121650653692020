export function validateClinicalDetails(values: any) {
  const { allergies, medications, medicalHistory } = values;
  const errors: any = {};

  if (allergies) {
    allergies.forEach((allergy: string, index: number) => {
      if(!allergy)
        errors[`allergies.${index}`] = "Allergy can't be empty";
    });
  }

  if (medications) {
    medications.forEach((medication: string, index: number) => {
      if(!medication)
        errors[`medications.${index}`] = "Medication can't be empty";
    });
  }

  if (medicalHistory) {
    medicalHistory.forEach((mh: string, index: number) => {
      if(!mh)
        errors[`medicalHistory.${index}`] = "Medical history can't be empty";
    });
  }

  return errors;
}
