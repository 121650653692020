import { create } from "./index";

interface RefundAllowanceBody {
  clientId: number;
  userId: string;
  bookingId: number;
}

async function refundAllowance(args: RefundAllowanceBody) {
    const { clientId, bookingId } = args;
    const bookingRefundEndpoint = `/admin/clients/${clientId}/bookings/${bookingId}/refund`;
    const { data } = await create(bookingRefundEndpoint)

    return {
        data,
    };
}

export default refundAllowance;
