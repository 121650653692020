import React, { useState } from "react";
import {
  Button,
  Confirm,
  TextField,
  Labeled,
  useCreate,
  useNotify,
  useRecordContext,
  useGetOne,
  useGetMany,
  FunctionField
} from "react-admin";
import { ShowTabProps } from "../../../types/utilities";
import RefundIcon from "@mui/icons-material/Payment";
import CancelIcon from '@mui/icons-material/CancelPresentation';
import PaidIcon from '@mui/icons-material/Paid';
import { FormTab } from "../../form/CustomTabForm/FormTab";
import { Heading } from "../../ui/Heading";
import { Grid, Tooltip } from "@mui/material";
import DateField from "../../fields/DateField";
import { EmptyTab } from "../../layout/EmptyTab";
import { Group } from "../../layout/Group";
import { Stack } from "../../layout/Stack";
import capitalize from 'lodash/capitalize';
import { useFlag } from "../../../featureFlags";
import { 
  TOGGLE_DISPLAY_BOOKING_PAYMENT_ACTIONS, 
  TOGGLE_ENABLE_CLINICAL_PRODUCTS,
  TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS 
} from "../../../featureFlags/flags";
import { AllowanceDetails } from "./PaymentsTab/AllowanceDetails";

const paymentStatuses = {
  None: "None",
  AwaitingAuthorization: "AwaitingAuthorization",
  Authorized: "Authorized",
  Captured: "Captured",
  Failed: "Failed",
  Canceled: "Canceled",
  Refunded: "Refunded",
  RefundRequested: "RefundRequested"
};

function getRefundForbiddenReason(paymentStatus: string): string {
  switch (paymentStatus) {
    case paymentStatuses.None:
    case paymentStatuses.AwaitingAuthorization:
    case paymentStatuses.Authorized:
      return "Payment has not been captured yet, and so cannot be refunded";

    case paymentStatuses.Failed:
      return "Payment has failed, and so cannot be refunded";

    case paymentStatuses.Canceled:
      return "Payment has been cancelled, and so cannot be refunded";

    case paymentStatuses.Refunded:
      return "Payment has already been refunded";

    case paymentStatuses.RefundRequested:
      return "Payment refund has already been requested";

    default:
      return "";
  }
}

function getCancelForbiddenReason(paymentStatus: string): string {
  switch (paymentStatus) {
    case paymentStatuses.None:
    case paymentStatuses.AwaitingAuthorization:
      return "Payment has not been authorized yet, and so cannot be cancelled";

    case paymentStatuses.Captured:
      return "Payment has already been captured, and so can only be refunded";

    case paymentStatuses.Failed:
      return "Payment has failed, and so cannot be cancelled";

    case paymentStatuses.Canceled:
      return "Payment has already been cancelled";

    case paymentStatuses.Refunded:
      return "Payment has already been refunded, and so cannot be cancelled";

    case paymentStatuses.RefundRequested:
      return "Payment refund has already been requested, payment cannot be cancelled";

    default:
      return "";
  }
}

function getCaptureForbiddenReason(paymentStatus: string, bookingStatus: string): string {
  if (bookingStatus !== "Completed") {
    return "Payment can only be captured after the booking is completed"
  }

  switch (paymentStatus) {
    case paymentStatuses.None:
    case paymentStatuses.AwaitingAuthorization:
      return "Payment has not been authorized yet, and so cannot be captured";

    case paymentStatuses.Captured:
      return "Payment has already been captured";

    case paymentStatuses.Failed:
      return "Payment has failed, and so cannot be captured";

    case paymentStatuses.Canceled:
      return "Payment has been cancelled, and so cannot be captured";

    case paymentStatuses.Refunded:
      return "Payment has been refunded, and so cannot be captured";

    case paymentStatuses.RefundRequested:
      return "Payment refund has been requested, payment cannot be captured";
  }

  return "";
}

function getPaymentType(order: any) {
  return order.paymentDetails.providers.psp ? "PSP" : "Unknown";
}

function getPaymentProviderType(order: any) {
  return order.paymentDetails.providers.psp
    ? capitalize(order.paymentDetails.providers.psp.type)
    : "N/A";
}

export function PaymentsTab(props: ShowTabProps) {
  const record = useRecordContext();
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const featureFlagEnabled = clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag;
  
  const { data } = useGetMany('orders', {
    ids: [record.attributes.serviceOrderId],
    meta: {
      clientId: record.clients[0].id,
      userId: record.users[0].id
    }
  });

  if (!data || !data.length) {
    return null;
  }

  const [order] = data;
  order.bookingStatus = record.attributes.status;
  order.bookingServiceOrderId = record.attributes.serviceOrderId;

  const clientHasClinicalProductsEnabled = record.clients?.[0]?.attributes?.clinicalProductsEnabled && featureFlagEnabled;
  const usedClinicalProductAllowance = Boolean(!order.bookingServiceOrderId);
  const shouldDisplayAllowanceDetails = clientHasClinicalProductsEnabled && usedClinicalProductAllowance;

  return (
    <FormTab {...props} label="Payments" editable="noneditable">
      {
        shouldDisplayAllowanceDetails
          ? <AllowanceDetails /> 
          : <PaymentDetails record={order} />
      }
    </FormTab>
  );
}

function PaymentDetails(props: any) {
  const orderRecord = useRecordContext(props);
  const isAbleToPerformPaymentActions = useFlag(TOGGLE_DISPLAY_BOOKING_PAYMENT_ACTIONS);

  if (!orderRecord.paymentDetails) {
    return (
      <EmptyTab emptyText="There is no payment associated with this booking" />
    );
  }

  return (
    <Group heading={<Heading level={2}>Payments details</Heading>}>
      <Stack>
        <Labeled label="Order Id">
          <TextField record={orderRecord} source="id" />
        </Labeled>
      </Stack>
      <Grid item xs={12} sm={6}>
        <Labeled label="Payment Type">
          <FunctionField render={(record: any) => getPaymentType(record)} record={orderRecord} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Payment Provider">
          <FunctionField render={(record: any) => getPaymentProviderType(record)} record={orderRecord} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Status">
          <TextField record={orderRecord} source="paymentDetails.status" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField record={orderRecord} source="paymentDetails.paymentDate" label="Payment Date" showTime />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField record={orderRecord} source="paymentDetails.createdDate" label="Created Date" showTime />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField record={orderRecord} source="paymentDetails.updatedDate" label="Updated Date" showTime />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Amount">
          <TextField record={orderRecord} source="paymentDetails.amount" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Currency">
          <TextField record={orderRecord} source="paymentDetails.currency" />
        </Labeled>
      </Grid>

      { isAbleToPerformPaymentActions && 
        <>
          <RefundPaymentButton record={orderRecord} />
          <CancelPaymentButton record={orderRecord} />
          <CapturePaymentButton record={orderRecord} />
        </>
      }
    </Group>
  );
}

function RefundPaymentButton(props: any) {
  const record = useRecordContext(props);
  const paymentStatus = String(record.paymentDetails.status);
  const [isDialogOpen, setDialogOpenState] = useState(false);
  const notify = useNotify();

  const [refundOrder, { isLoading }] = useCreate("refundOrder",
    {
      data: {
        orderId: record.id,
        clientId: record.paymentDetails.clientId,
        userId: record.paymentDetails.userId,
        requestBody: {
          amount: record.paymentDetails.amount
        }
      },
    },
    {
      onSuccess: () => {
        setDialogOpenState(false);
        notify("Payment refund has been requested");
      },
      onError: () => {
        notify("Something went wrong while payment refunding");
      },
    }
  );

  const isDisabled = isLoading || paymentStatus !== paymentStatuses.Captured;
  const refundForbiddenReason = getRefundForbiddenReason(paymentStatus);

  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        title="Refund payment"
        content="Are you sure you want to refund this user's payment?"
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={() => {
          refundOrder();
        }}
        onClose={() => setDialogOpenState(false)}
      />
      <Tooltip title={refundForbiddenReason}>
        <span>
          <Button
            sx={{ margin: 1 }}
            disabled={isDisabled}
            label="Refund Payment"
            variant="outlined"
            onClick={() => setDialogOpenState(true)}
          >
            <RefundIcon />
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

function CancelPaymentButton(props: any) {
  const record = useRecordContext(props);
  const paymentStatus = String(record.paymentDetails.status);
  const [isDialogOpen, setDialogOpenState] = useState(false);
  const notify = useNotify();

  const [cancelOrder, { isLoading }] = useCreate("cancelOrder",
    {
      data: {
        orderId: record.id,
        clientId: record.paymentDetails.clientId,
        userId: record.paymentDetails.userId
      }
    },
    {
      onSuccess: () => {
        setDialogOpenState(false);
        notify("Payment has been cancelled");
      },
      onError: () => {
        notify("Something went wrong while payment cancelling");
      },
    }
  );

  const isDisabled = isLoading || paymentStatus !== paymentStatuses.Authorized;
  const cancelForbiddenReason = getCancelForbiddenReason(paymentStatus);

  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        title="Cancel payment"
        content="Are you sure you want to cancel this user's payment?"
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={() => {
          cancelOrder();
        }}
        onClose={() => setDialogOpenState(false)}
      />
      <Tooltip title={cancelForbiddenReason}>
        <span>
          <Button
            sx={{ margin: 1 }}
            disabled={isDisabled}
            label="Cancel Payment"
            variant="outlined"
            onClick={() => setDialogOpenState(true)}
          >
            <CancelIcon />
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

function CapturePaymentButton(props: any) {
  const record = useRecordContext(props);
  const paymentStatus = String(record.paymentDetails.status);
  const bookingStatus = String(record.bookingStatus);
  const [isDialogOpen, setDialogOpenState] = useState(false);
  const notify = useNotify();

  const [captureOrderPayment, { isLoading }] = useCreate("captureOrderPayment",
    {
      data: {
        orderId: record.id,
        clientId: record.paymentDetails.clientId,
        userId: record.paymentDetails.userId
      }
    },
    {
      onSuccess: () => {
        setDialogOpenState(false);
        notify("Payment has been captured");
      },
      onError: () => {
        notify("Something went wrong while payment capturing");
      },
    }
  );

  const isDisabled = isLoading
    || paymentStatus !== paymentStatuses.Authorized
    || bookingStatus !== "Completed";

  const captureForbiddenReason = getCaptureForbiddenReason(paymentStatus, bookingStatus);

  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        title="Capture payment"
        content="Are you sure you want to capture this user's payment?"
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={() => {
          captureOrderPayment();
        }}
        onClose={() => setDialogOpenState(false)}
      />
      <Tooltip title={captureForbiddenReason}>
        <span>
          <Button
            sx={{ margin: 1 }}
            disabled={isDisabled}
            label="Capture Payment"
            variant="outlined"
            onClick={() => setDialogOpenState(true)}
          >
            <PaidIcon />
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
