import {
  Edit,
  NumberInput,
  ReferenceArrayInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Title,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { EditActions } from "../Actions";
import React from "react";
import get from "lodash/get";
import AutocompleteInput from "../inputs/AutocompleteInput";
import validateClinicalService from "./validateClinicalService";
import { BooleanInput, ReferenceInput, SelectInput } from "ra-ui-materialui";
import { clinicalServiceDeliveryTypes } from "../../constants/constants";
import { useWatch } from "react-hook-form";
import { Heading } from "../ui/Heading";

function PageTitle() {
  const record = useRecordContext();
  const name = get(record, "attributes.name");

  return <Title title={name} />;
}


export function ClinicalServiceEdit() {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Edit
      mutationMode="pessimistic"
      title={<PageTitle />}
      actions={<EditActions />}
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to clinical service were successful");
          refresh();
        },
      }}
    >
      <SimpleForm
        validate={validateClinicalService}
        noValidate
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }>
          <Heading level={2}>General Details</Heading>
          <TextInput
            required
            label="Name"
            source="attributes.name"
          />
          <SelectInput
            choices={clinicalServiceDeliveryTypes}
            source="attributes.deliveryType"
            label="Delivery Type"
            required
            helperText={false}
          />
          <TextInput
            label="PMS Code"
            source="attributes.pmsCode"
          />
          <AppointmentDurationInput/>
          <ReferenceArrayInput
            source="attributes.shiftTypeId"
            reference="quinyxShiftTypes"
          >
            <AutocompleteInput
              source="id"
              label="Shift Types"
              optionText="attributes.name"
              showClearButton={true}
            />
          </ReferenceArrayInput>
          <BooleanInput
            label="Notifications Enabled"
            source="attributes.notificationsEnabled"
          />
          <BooleanInput
            label="Recording Enabled"
            source="attributes.recordingEnabled"
          />


        <Heading level={2}>Release Settings</Heading>
        <BooleanInput
          label="Enable Release Settings"
          source="attributes.canBeReleased"
        />
        <ReleaseSettings/>
      </SimpleForm>
    </Edit>
  )
}

function AppointmentDurationInput() {
  const deliveryType = useWatch({name: "attributes.deliveryType"});
  const isDeliveryTypeAsynchronous = deliveryType === "Asynchronous";

  if (isDeliveryTypeAsynchronous) return null;

  return <NumberInput
    required
    label="Appointment Duration"
    source="attributes.appointmentDuration"
  />
}

function ReleaseSettings() {
  const serviceId = useWatch({name: "id"});
  const canBeReleased = useWatch({name: "attributes.canBeReleased"});

  if (!canBeReleased) return null;

  return (
    <>
      <NumberInput
        required
        label="Release Threshold"
        source="attributes.releaseThreshold"
      />
      <ReferenceInput
        source="attributes.fallbackServiceId"
        reference="clinicalServices"
        filter={{excludedServices: [serviceId]}}
      >
        <AutocompleteInput
          label="Fallback Clinical Service"
          optionText="attributes.name"
          filterToQuery={query => ({
            name: query
          })}
        />
      </ReferenceInput>
    </>
  );
}




