import { useGetList, useGetOne } from "ra-core";
import React, { createContext, useContext, useState } from "react";
import { GooglePlacesAddress } from "../components/inputs/GooglePlace/GooglePlaceAddressInputs";
import { Address } from "../types/common";

export interface ExtendedAddress extends Address {
  id: string;
}
interface Context {
  currentAddress: GooglePlacesAddress;
  addresses: GooglePlacesAddress[];
  selectAddress: (id: string) => void;
  requestAddresses: (postcode: string | null) => void;
  type: string;
  loading: boolean;
}

const defaultAddress: GooglePlacesAddress = {
  id: "",
  name: "",
  phoneNumber: "",
  address: "",
  city: "",
  postCode: "",
  countryCode: "",
  vicinity: "",
};

const GooglePlaceAddressContext = createContext<Context>({
  currentAddress: defaultAddress,
  addresses: [],
  selectAddress: () => null,
  requestAddresses: () => null,
  type: "GP Surgery",
  loading: false,
});

interface ProviderProps {
  children: React.ReactNode;
  type: string;
}

const resource = "googlePlaces";

export function GooglePlaceContextProvider(props: ProviderProps) {
  const { children, type } = props;

  const [currentAddressId, setCurrentAddressId] = useState<string>("");
  const [postcode, setPostcode] = useState<string | null>(null);
  const [addresses, setAddresses] = useState<GooglePlacesAddress[]>([]);

  const { isLoading } = useGetList<GooglePlacesAddress>(
    resource,
    {
      pagination: {
        page: 0,
        perPage: 0,
      },
      sort: { field: "id", order: "asc" },
      filter: {
        type,
        postcode,
      },
    },
    {
      enabled: Boolean(postcode),
      refetchOnWindowFocus: false,
      onSuccess: (places: any) => {
        setAddresses(places.data || []);
      },
      onError: () => {
        setAddresses([]);
      },
    }
  );

  const getSinglePlace = useGetOne<GooglePlacesAddress>(
    resource,
    { id: currentAddressId },
    {
      enabled: Boolean(currentAddressId),
      refetchOnWindowFocus: false,
    }
  );

  const getCurrentAddress = (
    place: GooglePlacesAddress | undefined
  ): GooglePlacesAddress => {
    if (place) {
      return place;
    }

    return defaultAddress;
  };

  const currentAddress = getCurrentAddress(getSinglePlace?.data);

  return (
    <GooglePlaceAddressContext.Provider
      value={{
        currentAddress,
        addresses,
        selectAddress: setCurrentAddressId,
        requestAddresses: setPostcode,
        type,
        loading: isLoading,
      }}
    >
      {children}
    </GooglePlaceAddressContext.Provider>
  );
}

export const useGooglePlaceAddressContext = () =>
  useContext(GooglePlaceAddressContext);
