import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Datagrid, Filter, Labeled, List, RaRecord, TextInput, useRecordContext } from "react-admin";
import { useParams } from "react-router-dom";
import { ArrayField, FunctionField, SelectInput, SingleFieldList, TextField } from "ra-ui-materialui";
import { Chip, Typography } from "@mui/material";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { BooleanField } from "../../fields/BooleanField";
import { Heading } from "../../ui/Heading";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { getStringDate } from "../helpers/getStringDate";
import get from "lodash/get";
import { splitByCapitalLetters } from "../../../utilities/splitByCapitalLetters";


function ClinicalProductsListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Name"
        source="name"
        alwaysOn
        autoComplete="off"
      />
      {props.clientClinicalProductsEnabled &&
        <TextInput
          label="Code"
          source="code"
          alwaysOn
          autoComplete="off"
        />
      }
      {props.clientClinicalProductsEnabled &&
        <SelectInput
          label={"Enabled"}
          source="isEnabled"
          alwaysOn
          choices={[{ id: true, name: "Enabled" }, { id: false, name: "Disabled" }]}
        />
      }
    </Filter>
  );
}

export function ClinicalProductsShowTab(props: ShowTabProps) {
  const { id: clientId } = useParams();
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const record = useRecordContext();
  const clinicalProductsEnabled = get(record, "attributes.clinicalProductsEnabled");


  return (
    <Tab label={"Clinical Products"} {...props}>
      <Heading level={2}>Clinical Products</Heading>
      <BooleanField
        label="Enable clinical products"
        source="attributes.clinicalProductsEnabled"
      />
      {clinicalProductsEnabled ?
        <>
          <Labeled label="Allow paid services when the allowance limit is exceeded">
            <BooleanField
              source="attributes.allowPaidServicesWhenAllowanceExceeded"
            />
          </Labeled>
          <List
            title={" "} // no title
            resource="clinicalProducts"
            filters={<ClinicalProductsListFilter />}
            actions={false}
            empty={false}
            filter={{ clientId }}
            queryOptions={{
              meta: {
                includes: ["clientIdentifiers,employers"],
              },
            }}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<NoRecordsMessage message={"No clinical products are associated with this client."} />}
            >
              <TextField
                label={"Name"}
                source={"attributes.name"}
                sortable={false}
              />
              {clientClinicalProductsEnabledFlag &&
                <TextField
                  label="Code"
                  source="attributes.code"
                  sortable={false}
                />
              }
              {clientClinicalProductsEnabledFlag &&
                <BooleanField
                  label="Enabled"
                  source="attributes.isEnabled"
                  sortable={false}
                />
              }
              {clientClinicalProductsEnabledFlag &&
                <FunctionField
                  render={(rec: RaRecord) => {
                    return splitByCapitalLetters(rec.attributes.allowanceRenewalFrequency);
                  }}
                  label="Allowance Renewal Frequency"
                  sortable={false}
                />}
              {clientClinicalProductsEnabledFlag &&
                <FunctionField
                  render={(rec: RaRecord) => {
                    if (!rec.attributes.maxUsageTimeFrame) return "N/A";

                    const { day, month } = rec.attributes.maxUsageTimeFrame;
                    return getStringDate(day, month);
                  }}
                  label="Allowance Renewal Date"
                  emptyText="Not specified"
                  hideLabel
                  sortable={false}
                />
              }
              {clientClinicalProductsEnabledFlag &&
                <ArrayField label={"Services"} source="attributes.clinicalProductServices" sortable={false}>
                  <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                    <FunctionField label="Services" render={(rec: any) =>
                      <Chip
                        sx={{ margin: "4px" }}
                        label={`${rec.specialty ?? rec.clinicalService.name} - ${rec.maxUsageCount ?? "Unlimited"}`}
                      />
                    } />
                  </SingleFieldList>
                </ArrayField>
              }
              <FunctionField label={"Client Identifiers"} render={(rec: any) => {
                return rec.clientIdentifiers?.map((r: any) => <Typography
                  key={r.id}>{r.attributes?.clientIdentifier}</Typography>);
              }} />
              <FunctionField label={"Employers"} render={(rec: any) => {
                return rec.employers?.map((r: any) => <Typography key={r.id}>{r.attributes?.name}</Typography>);
              }} />
            </Datagrid>
          </List>
        </> : null}
    </Tab>);
}
