import { Box } from "@mui/material";
import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  Pagination, SingleFieldList, ChipField, useRecordContext,
} from "react-admin";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { ArrayField } from "ra-ui-materialui";
import get from "lodash/get";

export function ListExistingIdentifiers() {
  const record = useRecordContext();
  const clinicalProductsEnabled = get(record, "attributes.clinicalProductsEnabled");
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  const showClinicalProducts = clinicalProductsEnabled && (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag);

  return (
    <Box>
      <ReferenceManyField
        reference="clientIdentifiers"
        target="client.id"
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            label="Client Identifier"
            source="attributes.clientIdentifier"
            sortable={false}
          />
          <TextField
            label="Name"
            source="attributes.name"
            sortable={false}
          />
          {showClinicalProducts ?
            <ArrayField label={"Clinical Product"} source="clinicalProducts" sortable={false}>
              <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                <ChipField source="attributes.name" />
              </SingleFieldList>
            </ArrayField>
            : null
          }
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
}
