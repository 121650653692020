import React from "react";
import { ArrayField, Datagrid, SingleFieldList, TextField } from "ra-ui-materialui";
import { BooleanField } from "../../fields/BooleanField";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { ChipField, Labeled, Pagination, ReferenceManyField, useRecordContext } from "react-admin";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { useFlag } from "../../../featureFlags";
import get from "lodash/get";

export function EmployersShowTab(props: ShowTabProps) {
  const record = useRecordContext();
  const clinicalProductsEnabled = get(record, "attributes.clinicalProductsEnabled");
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  const showClinicalProducts = clinicalProductsEnabled && (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag);

  const employersEnabled = get(record, "employerSettings.attributes.employerRequiredOnBookings");

  return (
    <Tab {...props} label="Employers">
      <Heading level={2}>Employers Configuration</Heading>
      <BooleanField
            label="Should capture employer"
            source="employerSettings.attributes.employerRequiredOnBookings"
            emptyText="No"
          />
      {employersEnabled ?
        <>
          <Labeled label="Allow 'other' option">
            <BooleanField
              source="employerSettings.attributes.allowOtherEmployeeOption"
              emptyText="No"
            />
          </Labeled>
          <ReferenceManyField
            reference="clientEmployers"
            target="client.Id"
            label={false}
            pagination={<Pagination />}
            sort={{ field: "name", order: "ASC" }}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<NoRecordsMessage message={"No employers are associated with this client."} />}
            >
              <TextField label="Name" source="attributes.name" sortBy={"name"} />
              {showClinicalProducts ?
                <ArrayField label={"Clinical Products"} source="clinicalProducts" sortable={false}>
                  <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                    <ChipField source="attributes.name" />
                  </SingleFieldList>
                </ArrayField>
                : null}
            </Datagrid>
          </ReferenceManyField>
        </> : null}
    </Tab>
  );
}
