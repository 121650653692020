import { update } from "../index";
import { ClinicalProductServiceDTO } from "../../types/clinicalProducts";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../featureFlags/flags";


async function updateClinicalProduct(args: any) {
  const clientClinicalProductsEnabledFlag = FeatureFlagService.getInstance().getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const { id, servicesIds } = args.data;
  const { name, code, isEnabled, allowanceRenewalFrequency, maxUsageTimeFrame } = args.data.attributes;

  const endpoint = clientClinicalProductsEnabledFlag ?
    `/ms/clients/${args.data.attributes.clientId}/clinicalProducts/${id}` :
    `/ms/clinicalProducts/${id}`;


  const clinicalProductServices = clientClinicalProductsEnabledFlag ?
    args.data.attributes.clinicalProductServices :
    servicesIds.map((serviceId: any): ClinicalProductServiceDTO["data"]["attributes"] => {
      if (isNaN(serviceId)) {
        return {
          specialty: serviceId,
        } as ClinicalProductServiceDTO["data"]["attributes"];
      } else {
        return {
          clinicalServiceId: serviceId,
        } as ClinicalProductServiceDTO["data"]["attributes"];
      }
    });

  const body = {
    data: {
      id,
      type: "clinicalProducts",
      attributes: {
        name,
        code,
        isEnabled,
        maxUsageTimeFrame,
        allowanceRenewalFrequency,
        clinicalProductServices: clinicalProductServices
      }
    }
  };


  return await update(endpoint, body);
}

export default updateClinicalProduct;
