export const gpQualifications: Record<string, string> = {
  "https://www.gmc-uk.org/": "GMC",
  "https://medicalcouncil.ie/": "IMC",
};

export const ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER =
  "urn:healthhero:encounter:adastra:case-number";
export const ADASTRA_CASE_REFERENCE_SYSTEM_IDENTIFIER =
  "urn:healthhero:encounter:adastra:case-reference";
export const ADASTRA_PATIENT_REFERENCE_SYSTEM_IDENTIFIER =
  "urn:healthhero:patient:adastra:reference";
export const FHIR_ORGANIZATION_API_URN_PREFIX =
  "urn:emr:healthhero:global:Organization:id:";
