import { isNonEmptyFormLevel } from "../../validators/nonEmpty";
import validateGpAddress from "../../validators/validateGpAddress";
import validatePatientAddress from "../../validators/validatePatientAddress";
import validatePharmacyAddress from "../../validators/validatePharmacyAddress";
import getCurrentDate from "../../utilities/getCurrentDate";
import { minDate } from "../../validators/minDate";
import { isVNetCaseService } from "./helpers/vNetUtils";

type ErrorMessage = string;

interface MessageDoctorValidation {
  userId?: ErrorMessage;
  service?: ErrorMessage;
  appointmentId?: ErrorMessage;
  consultationType?: ErrorMessage;
  messageForTheDoctor?: ErrorMessage;
  employerId?: ErrorMessage;
  clientIdentifier?: ErrorMessage;
}

interface VideoBookingValidation {
  userId?: ErrorMessage;
  service?: ErrorMessage;
  reasonForBooking?: ErrorMessage;
  phoneNumber?: ErrorMessage;
  appointmentId?: ErrorMessage;
  consultationType?: ErrorMessage;
  employerId?: ErrorMessage;
  clientIdentifier?: ErrorMessage;
  vnetIntakeReference?: ErrorMessage;
  vnetCaseReference?: ErrorMessage;
}

function shouldValidateVNetCase(values: any){
  const { client, isSchedulerBooking, appointment } = values;
  const service = isSchedulerBooking ? appointment?.attributes?.clinicalServiceId : values.service;
  return isVNetCaseService(client, service);
}

export function validateVideoBooking(values: any) {
  const errors: VideoBookingValidation = {};

  if (!values.userId) {
    errors.userId =
      "A userId is required. You can automatically add this by clicking 'Create a booking' in the patient's detail page";
  }

  if (values.enableIntakeCreation !== undefined && !values.enableIntakeCreation && !values.vnetIntakeReference) {
    errors.vnetIntakeReference = "An Intake Reference is required when automatic creation disabled";
  }
  
  if (shouldValidateVNetCase(values) && !values.vnetCaseReference){
    errors.vnetCaseReference = "A case reference is required for this service";
  }

  if (!values.consultationType) {
    errors.consultationType = "You must choose a consultation type";
  }

  if (!values.isSchedulerBooking) {
    if (!values.appointmentId) {
      errors.appointmentId =
        "No appointment has been selected. Please choose an available appointment";
    }

    if (!values.service) {
      errors.service = "A service is required";
    }

    if (values.client?.attributes?.clinicalProductsEnabled && values.service && values.allowanceLeft === 0) {
      errors.service = "No allowance left for selected service";
    }
  }

  if (!values.reasonForBooking) {
    errors.reasonForBooking = "A reason for booking is required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "The patient's phone number is required";
  }

  if (values.client?.attributes?.employerRequiredOnBookings && !values.employerId) {
    errors.employerId = "You must select an employer";
  }

  if (values.client?.attributes?.clientIdentifierRequired && !values.clientIdentifier) {
    errors.clientIdentifier = "The patient's policy number is required";
  }

  if (values.clientIdentifier?.length > 512) {
    errors.clientIdentifier = "Max length of the policy number is 512 characters";
  }

  return errors;
}

export function validateMessageDoctor(values: any) {
  const errors: MessageDoctorValidation = {};

  if (!values.userId) {
    errors.userId =
      "A userId is required. You can automatically add this by clicking 'Create a booking' in the patient's detail page";
  }

  if (!values.consultationType) {
    errors.consultationType = "You must choose a consultation type";
  }

  if (!values.isSchedulerBooking) {
    if (!values.appointmentId) {
      errors.appointmentId =
        "No appointment has been selected. Please choose an available appointment";
    }

    if (!values.service) {
      errors.service = "A service is required";
    }

    if (values.client?.attributes?.clinicalProductsEnabled && values.service && values.allowanceLeft === 0) {
      errors.service = "No allowance left for selected service";
    }
  }

  if (!values.messageForTheDoctor) {
    errors.messageForTheDoctor =
      "The patient's message to the doctor is required";
  }

  if (values.client?.attributes?.employerRequiredOnBookings && !values.employerId) {
    errors.employerId = "You must select an employer";
  }

  if (values.client?.attributes?.clientIdentifierRequired && !values.clientIdentifier) {
    errors.clientIdentifier = "The patient's policy number is required";
  }

  if (values.clientIdentifier?.length > 512) {
    errors.clientIdentifier = "Max length of the policy number is 512 characters";
  }

  return errors;
}

const defaultMinDate = getCurrentDate();

function validateForm(values: any) {
  if (values.consultationType === "MessageDoctor") {
    const messageDoctorErrors = validateMessageDoctor(values);

    return messageDoctorErrors;
  }

  const generalErrors: Record<string, string> = {};

  const bookingErrors = validateVideoBooking(values);
  const addressErrors = validatePatientAddress(values);
  const pharmacyErrors = validatePharmacyAddress(values);
  const gpErrors = validateGpAddress(values);

  if (!isNonEmptyFormLevel(values.reasonForBooking)) {
    generalErrors.reasonForBooking = "A reason for booking is required";
  }

  const minDateMessage = minDate(defaultMinDate)(values.selectedDate);

  if (minDateMessage) {
    generalErrors.selectedDate = minDateMessage;
  }

  const errors = {
    ...bookingErrors,
    ...addressErrors,
    ...pharmacyErrors,
    ...gpErrors,
    ...generalErrors,
  };

  return errors;
}

export default validateForm;
